<template>
  <component :is="component" v-if="component" />
</template>

<script>
import kX8Va23bynX31IFdOhz3 from '@/views/booths/kX8Va23bynX31IFdOhz3.vue';
import BP642iTGBimehU72fq91 from '@/views/booths/BP642iTGBimehU72fq91.vue';
import O2BbZDj6LvHjkvvyzir7 from '@/views/booths/O2BbZDj6LvHjkvvyzir7.vue';

export default {
  data() {
    return {
      component: null,
    };
  },
  components: {
    kX8Va23bynX31IFdOhz3,
    BP642iTGBimehU72fq91,
    O2BbZDj6LvHjkvvyzir7,
  },
  computed: {},
  mounted() {
    this.component = this.$options.components[this.$route.params.id];
  },
};
</script>

<style>
</style>
